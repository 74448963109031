
html {

  height: 100%;

  width: 100%;

  overflow: hidden;

}

 

body {

  height: 100%;

  width: 100%;

  overflow: auto;
  margin: 0;
  background-color: #FAF7E1;
  -webkit-overflow-scrolling:touch;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;